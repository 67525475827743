html, body{
	max-width: 100%;
	overflow-x: hidden;
}

.mar0{
	margin: 0;
}

.pad0{
	padding: 0;
}

a{
	text-decoration: none;
	color: black;
}

.album{
	cursor: pointer;
	text-decoration: none;
	border: 2px solid #ddd;
	color: rgb(54, 54, 65);
}

.album:hover{
	color: rgb(172, 172, 177);
	background-color: rgb(54, 54, 65);
}

.album a:hover{
	color: white;
}

.crte{
	border-top: 1px solid #ddd;
	margin: 0 1em;
}

.glava-naslov{
	padding-top: 0.5em;
	padding-bottom: 1em;
	margin: 0 5em;
	border-bottom: solid #ddd 1px;
	font-size: 1em;
}

.glava-slika{
	padding-top: 3em;
}

.kontakt{
	padding: 2em 0 2em 0;
	background-color: rgb(54, 54, 65);
	color: #acacb1;
}

.naslovi{
	font-family: "Novecento Sans", "Raleway", "Noto Sans", "Open Sans", sans-serif;
	color: #8f7655;
}

.nav li{
	padding: 0 0.5rem;
}

.podnaslovi h2:hover{
	text-decoration: none;
	font-weight: 900;
}

.roka{
	cursor: pointer;
}

@media (max-width: 768px) {
	.podnaslovi h2{
		font-family: "Novecento Sans", "Raleway", "Noto Sans", "Open Sans", sans-serif;
		font-size: 0.8em;
		color: #a59e95;
	}

	.podnaslovi{
		padding: 0.8em 2em 0.7em 2em;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.podnaslovi h2{
		font-family: "Novecento Sans", "Raleway", "Noto Sans", "Open Sans", sans-serif;
		font-size: 1em;
		color: #a59e95;
	}

	.podnaslovi{
		padding: 0.8em 0 0.7em 0;
	}
}

@media (min-width: 992px){
	.podnaslovi h2{
		font-family: "Novecento Sans", "Raleway", "Noto Sans", "Open Sans", sans-serif;
		font-size: 1.2em;
		color: #a59e95;
	}

	.podnaslovi{
		padding: 0.8em 0 0.7em 0;
	}
}


